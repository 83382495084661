import { getStore, setStore,reportErrorFunc } from '@/utils/utils'
import {
    setting,
    targetChannels,
    getPsw,
    getToken,
    generateUser,
    lastSession,
    getAgentMsg,
    getAgentstate,
    setMessageRead,
    getUnReadNum,
    attributes,
    msgPredict,
    sendMsg
} from '@/fetch/api'
import to from 'await-to-js'
import Cookies from 'js-cookie'

export default {
    // 计算器
    
    // 组件
    components: {
        leftItem: () =>import ('@/components/common/leftItem/index'),
        rightItem: () =>import ('@/components/common/rightItem/index'),
        upload: () =>import ('@/components/common/upload'),
        leaveWord: () =>import ('@/components/common/leaveWord'),
        invite: () =>import ('@/components/common/invite'),
    },
    data() {
        return {
            isComplete: false, //是否初始化完成
            // isConnentSocket: false, //是否正在连接
            gwLocation: null, //保存官网的location
            socket: null, //websocket对象
            isSocketClose: false, //socket是否已断开
            autoReconnectNum: 0, //当前重连的次数
            wsLinkIndex:0,       //当前wss链接的index
            isAutoClose: false, //是否主动断开连接--超过10分钟没有操作就结束会话
            wsType:'json',    //websocket连接返回的数据格式
            message: '', //输入框输入的文本
            openAudio: true, //是否播放提示音
            showTips: true, //显示顶部提示框
            topTips: '', //顶部提示语的文本
            lineTips:"",   //排队人数、正在输入等特殊提示的文本
            historyList: [], //历史聊天记录
            curTalkList: [], //当前聊天记录
            currentSendList: [], //还未有ack响应的新数据，保存的是msg_id
            unReadSendList: [], //未阅消息的msg_id结合
            msgAckObj:{},        //ack响应的 但是还没有更新状态的数据
            unReadCount: 0, //未读消息数
            sendParams:null,   //发送消息方法的传参
            // 可更改的配置
            talkViewOpt:{
                isShowReadStatus:true,  //是否显示已读未读
                isShowUpload:true,      //是否可上传图片
            },
            maxCurData:1000,  //当前聊天列表允许的最大值
			delCurData:900,   //聊天列表的删除数
            onlineStatus:navigator.onLine,   //网络状态  true:连接  false：异常
            // 表情模块的数据
            showEmojs: false, //是否显示表情选择框
            curEmoji: 'demo1', //当前表情框的tab选择
            emojiObj: {    //表情框的数据
                demo1: {
                    icon: 'https://livechat.kr65.com/resources/images/webim/emoji/ee_1.png',
                    obj: this.$store.state.emojiData.emoji1,
                },
                demo2: {
                    icon: 'https://livechat.kr65.com/resources/images/webim/emoji2/160a.png',
                    obj: this.$store.state.emojiData.emoji2,
                },
            },
            showLeaveWord: false, //是否打开留言弹窗
            showInvite: false, //是否打开评价弹窗
            // 查看历史
            clickHis: false, //是否点击了查看更多
            showMoreBtn: true, //是否显示查看更多按钮
            configOpt: {
                configId: '',
                accid: '',
                tenantId: '',
            },
            targetConfig: {}, //保存目标信息 {"tenant_name":"","tenant_avatar":"","tenant_logo":"","org_name":"","app_name":"","im_service_number":1,"ws_server":"","autoReconnectNumMax":2,"autoReconnectInterval":2,"heartBeatWait":60,"delivery":true}
            visitorMsg: {}, //访客信息 {"id":0,"account":"","accid":"","password":"",nickname:""}
            lastSessionMsg: {}, //最近一次通话的数据 {last_agent_accid: "客服id",session_service_id: "会话id",}

            lastDateTime: '', //上一次的时间，用来发送完访客输入状态接口后的时间
            canGetSession: 1, //请求最后一次通话的开关，只请求一次
            dialogWidth:"",    //页面宽度
            isiframe:false,    //是否外嵌页面
            agentMsg:{        //保存客服的信息 头像 姓名 状态
                defaultText:"宜信云商务助手"
            }, 
            // 定时器模块的配置
            checkMsgTimer: null,  //消息ack回应定时器
            checkSocketTimer: null, //会话状态定时器 
            checkscrollTimer: null, //标题滚动的定时器
            // 心跳包定时器
            heartTimerOpt:{
                timer:null,  //定时器
                timestamp:0, //上次请求的时间
                space:10000, //请求的间隔时长
            },
            //客服状态定时器
            agentTimerOpt:{
                timer:null,  //定时器
                timestamp:0, //上次请求的时间
                space:10000, //请求的间隔时长
            },
            //token状态定时器
            tokenTimerOpt:{
                timer:null,  //定时器
                timestamp:0, //上次请求的时间
                space:3000, //请求的间隔时长
            },
            //获取未读消息定时器
            unreadTimerOpt:{
                timer:null,  //定时器
                timestamp:0, //上次请求的时间
                space:10000, //请求的间隔时长
            },
                 
        }
    },
    watch:{
        // 监听是否允许连接websocket
        "targetConfig.ws_default_enabled"(e){
            e&&this.initWebSocket();
        }
    },
    methods: {
        // 获取路由的参数
        getRouteMsg(str) {
            if (!str) return this.init(this.configOpt.configId) //获取信息并连接socket;
            let arr = str.split('&')
            arr.forEach((item) => {
                this.configOpt[item.split('=')[0]] = unescape(item.split('=')[1])
            })
            this.$emit("update:configId", this.configOpt.configId)
            this.init(this.configOpt.configId)
            
        },
        // 获取基础信息
        async init(configId) {
            if (!configId) return this.$message.warning('configId不能为空')
            this.isComplete = false;
            await this.getSetting(configId) // 获取渠道页信息
            await this.getTarget(this.configOpt.tenantId) // 获取目标渠道信息
            await this.generateUser();
            await this.getVisitorMsg(); // 获取访客的登录信息
            await this.getVisitorToken(); // 获取访客的token
            await this.lastSessionEvent(); //获取最后一次会话
            this.getCustomMsg();
            this.isAutoClose = false;

            this.sendMsgToParent('isShow', { status: true }) //发送消息给客户端，提示显示按钮
        },
        // 获取渠道页信息
        async getSetting(configId) {
            let params = {
                screen_width:this.isiframe?"":document.documentElement.clientWidth || document.body.clientWidth||"",
                screen_height:this.isiframe?"":document.documentElement.clientHeight || document.body.clientHeight||"",
            }
            const [s_err, s_res] = await to(setting({ configId: configId ,data:params}))
            if (s_err || s_res.code != 200) return
            console.log('==s_res.entity.configJson.ui==', s_res.entity);
            let ui = s_res.entity.configJson.ui;
            this.dialogWidth =ui.dialogWidth.replace('px', '') < '435' ? '435px' : ui.dialogWidth //聊天的最小宽度是435px
            this.sendMsgToParent('set_W_H', {width: this.dialogWidth,height: ui.dialogHeight,dialogPosition: ui.dialogPosition,themeName: ui.themeName}) //告诉主页面聊天弹窗的布局设置
            
            this.$emit('update:theme', ui.themeName) // 根据后端返回来更改主题颜色
            console.log("================================")
            // this.$emit()
            this.getSessionData(configId, s_res.entity.tenantId) // 获取侧边栏的数据
            this.configOpt.tenantId = s_res.entity.tenantId
            this.configOpt.configId = s_res.entity.configId
            this.settingConfig = {
                appKey: s_res.entity.configJson.channel.appKey,
                to_accid: s_res.entity.configJson.channel.to,
            }
        },

        /**
         * 获取目标渠道信息
         * @params tenantId      租户ID
         */
        async getTarget(tenantId) {
            if (!tenantId) return this.$message.warning('缺少tenantId')
            const [err, res] = await to(targetChannels({ tenantId: tenantId }))
            if (err || res.code != 200) return this.isComplete = true;
            console.log('===获取目标渠道信息 getTarget===', res.entity);
            res.entity.tenant_avatar = res.entity.tenant_avatar.indexOf('http')>-1?res.entity.tenant_avatar:res.entity.img_domain_url+"/"+res.entity.tenant_avatar
            this.targetConfig = {...res.entity };
        },
        // 获取访客登录信息
        getVisitorMsg() {
            return new Promise(async (resolve,reject)=>{
                const [err, res] = await to(getPsw({visitorAccid: this.configOpt.accid || Cookies.get('gv_visitor_accid'),}))
                if (err || res.code != 200) return this.isComplete = true;
                this.visitorMsg = {...res.entity }
                this.configOpt.accid == '' && (this.configOpt.accid = res.entity.accid)
                resolve()
            })
            
        },

        /**
         * 获取token
         * @params tenantId      租户ID
         * @params appName       应用名称
         * @params account       账号
         * @params password      密码
         */
         getVisitorToken(tenantId=this.configOpt.tenantId, appName=this.targetConfig.app_name, account=this.visitorMsg.account, password=this.visitorMsg.password) {
            return new Promise(async (resolve,reject)=>{
                if (!tenantId || !appName || !account || !password) return;
                let timestamp = new Date().getTime();
                if(timestamp - this.tokenTimerOpt.timestamp < this.tokenTimerOpt.space) return;
                this.tokenTimerOpt.timestamp = timestamp;
                const [err, res] = await to(getToken({tenantId: tenantId,appName: appName,data: {grant_type: 'password',account: account,password: password,},}))
                if (err || res.code != 200) return this.isComplete = true;
                setStore('token', res.entity.access_token)
                setStore('visitorAccid', this.configOpt.accid)
                resolve()
            })
        },

        /**
         *  注册(生成)访客帐号
         * @params tenantId      租户ID
         */
        async generateUser(tenantId=this.configOpt.tenantId) {
            if (!tenantId ) return
            const [err, res] = await to(generateUser({ tenantId: tenantId }))
            if (err || res.code != 200) return this.isComplete = true;
            this.configOpt.accid = res.entity.accid
        },

        /**
         * 开启最近的会话
         * @params tenantId      租户ID
         * @params visitorAccid  访客通讯ID
         * @params appName       应用名称
         * @params token
         */
        async lastSessionEvent(tenantId=this.configOpt.tenantId, visitorAccid=this.configOpt.accid, appName=this.targetConfig.app_name, token=getStore('token')) {
            if (!tenantId || !visitorAccid || !appName || !token || this.canGetSession != 1) return
            const [err, res] = await to(lastSession({tenantId: tenantId,visitorAccid: visitorAccid,data: {app_name: appName,token: token,},}))
            if (err || res.code != 200) return this.isComplete = true;
            this.lastSessionMsg = {
                last_agent_accid: res.entity.last_agent_accid,
                session_service_id: res.entity.session_service_id,
            }
            this.canGetSession = 0;
            this.isAutoClose = false;
            this.targetConfig.ws_default_enabled&&this.initWebSocket();
            !this.targetConfig.ws_default_enabled&&(this.isComplete = true);
            this.getMoreHistory() //获取历史数据
            this.checkUnRead() //轮询获取未读消息
            this.setMessagePredict() //记录访客环境信息
        },
        /**
         *  获取客服信息
         * @params tenantId      租户ID
         * @params agentAccid    客服通讯ID
         * @params appName       应用名称
         * @params visitorAccid  访客通讯ID
         */
        async getCustomMsg(tenantId=this.configOpt.tenantId, agentAccid=this.lastSessionMsg.last_agent_accid) {
            if (!tenantId || !agentAccid) return;
            const [err, res] = await to(getAgentMsg({ tenantId: tenantId, agentAccid: agentAccid, }))
            if (err || res.code != 200) return;
            this.agentMsg.avatar = res.entity.avatar.indexOf('http')>-1?res.entity.avatar:this.targetConfig.img_domain_url+"/"+res.entity.avatar;
            this.agentMsg.nickname =  res.entity.nickname;
            this.agentMsg.status =  res.entity.state||"offline";
            this.targetConfig.ws_default_enabled = this.agentMsg.status=="online"?true:this.targetConfig.ws_default_enabled;
            this.sendMsgToParent('customStatus', { ...this.agentMsg });
            this.$emit("getAgent",this.agentMsg);
            this.checkCustomerStatus();  //轮询客服状态            
        },
        /**
         *  获取客服在线状态 
         * @params tenantId      租户ID
         * @params agentAccid    客服通讯ID
         * @params appName       应用名称
         * @params visitorAccid  访客通讯ID
         */
        async getCustomStatus(tenantId=this.configOpt.tenantId, agentAccid=this.lastSessionMsg.last_agent_accid, appName=this.targetConfig.app_name, visitorAccid=this.configOpt.accid, token = getStore('token')) {
            if (!tenantId || !agentAccid || !appName || !visitorAccid) return;
            let timestamp = new Date().getTime();
            if(timestamp - this.agentTimerOpt.timestamp < this.agentTimerOpt.space) return;
            this.agentTimerOpt.timestamp = timestamp;
            const [err, res] = await to(getAgentstate({ tenantId: tenantId, agentAccid: agentAccid, data: { app_name: appName, user_name: visitorAccid, token: token }, }))
            if (err || res.code != 200) return;
            res.entity.state=="online"&&(this.targetConfig.ws_default_enabled = true)
            if(this.agentMsg.status!=res.entity.state){
                this.agentMsg.status =  res.entity.state;
                this.sendMsgToParent('customStatus', { ...this.agentMsg });
                this.$emit("getAgent",this.agentMsg)
            }
        },
        /**
         * 记录访客环境信息
         *  @params string tenantId      租户ID
         *  @params string sessionId     会话ID
         *  @params Object locationObj
         */
        async setMessagePredict(tenantId=this.configOpt.tenantId, sessionId=this.lastSessionMsg.session_service_id, locationObj=this.gwLocation) {
            locationObj = locationObj || window.location
            const [err, res] = await to(attributes({tenantId: tenantId,sessionId: sessionId,data: {referer: locationObj.origin,access_url: locationObj.href,keyword: locationObj.search,},}))
            if (err || res.code != 200) return
        },
        /**
         *  获取未读消息数 (轮询,每30一次请求)
         * @params tenantId      租户ID
         * @params agentAccid    客服通讯ID
         * @params appName       应用名称
         * @params visitorAccid  访客通讯ID
         */
        async getUnRead(tenantId=this.configOpt.tenantId, visitorAccid=this.configOpt.accid) {
            if (!tenantId || !visitorAccid) return;
            let timestamp = new Date().getTime();
            if(timestamp - this.unreadTimerOpt.timestamp < this.unreadTimerOpt.space) return;
            this.unreadTimerOpt.timestamp = timestamp;
            const [err, res] = await to(getUnReadNum({ tenantId: tenantId, visitorAccid: visitorAccid }))
            if (err || res.code != 200) return
            console.log('===getUnRead===', res.entity)
            if (res.entity.count > 0) {
                this.unReadCount = res.entity.count
                this.TitleScrolling(res.entity.last_message.content)
                this.sendMsgToParent('getUnRead', { num: res.entity.count,tips:res.entity.last_message.content })
            }
        },

        /**  设置消息已读
         *  @params string tenantId 访客通讯ID
         *  @params string visitorAccid 访客通讯ID
         *  @params string toAccid 访客通讯ID
         *  @params string msgid 消息id
         */
        async setMessageReadEvent(msgid,read_all=false,tenantId=this.configOpt.tenantId, sessionId=this.lastSessionMsg.session_service_id, visitorAccid=this.configOpt.accid, toAccid=this.lastSessionMsg.last_agent_accid) {
            if (!tenantId || !visitorAccid || !toAccid) return;
            const [err, res] = await to(setMessageRead({tenantId: tenantId, data: {session_id: sessionId,curr_accid: visitorAccid,accid: toAccid,msg_id: msgid,read_all:read_all}}))
            if (err || res.code != 200) return;
            let index = this.curTalkList.findIndex(item => item.msg_id == msgid);
            index > -1 && (this.curTalkList[index].status = 2);
        },

        /**
         * 访客正在输入内容中
         *  @params string sessionId    通讯ID
         *  @params string visitorAccid 访客通讯ID
         *  @params string content      内容
         */
        async visitorInput(content,sessionId=this.lastSessionMsg.session_service_id, visitorAccid=this.configOpt.accid) {
            const [err, res] = await to(msgPredict({sessionId: sessionId,visitorAccid: visitorAccid,data: { content: content },}))
            if (err || res.code != 200) return
        },

        /**
         * 发送消息
         *  @params Object params    传参
         */
        async sendMsgApi(params){
            const [err, res] = await to(sendMsg(params))
            return res.code==200?Promise.resolve(res.entity):Promise.reject(err)
        },
        // -------------------------websocket 连接开始--------------------------------
        /**
         * initWebSocket  开始连接socket
         */
        initWebSocket() {
            let wsUrl = this.wsLinkIndex!=-1?this.targetConfig.link[this.wsLinkIndex]:this.targetConfig.default_link;
            if (typeof WebSocket === 'undefined') {
                alert('您的浏览器不支持socket')
            } else {  
                if (!wsUrl||!!this.socket||this.isAutoClose||!this.configOpt.accid) return;
                this.socket = new WebSocket(wsUrl) // 实例化socket
                this.socket.onopen = this.openSocket // 监听socket连接
                this.socket.onerror = this.errorSocket // 监听socket错误信息
                this.socket.onclose = this.closeSocket // 监听socket错误信息
                this.socket.onmessage = this.msgSocket // 监听socket消息
            }
        },
        //连接建立之后执行send方法发送数据
        openSocket() {
            this.autoReconnectNum = 0 //重置重连的次数
            this.isAutoClose = false;
            clearTimeout(this.$store.state.errorTimer);            
            console.log('==========webSocket连接成功 onopen=============')
            this.initLogin() // 发送登录消息
            this.startHeart(this.configOpt.accid, this.targetConfig.heartBeatWait) //开始心跳包
            this.checkSocket() // 轮询判断 超过10分钟没有对话，则断开
            this.checkCustomerStatus();  //轮询客服状态
            this.checkUnRead();
        },
        //连接建立失败重连
        errorSocket(error) {
            console.error('webSocket连接失败：'+error)
            reportErrorFunc({action: "webSocket连接",request_url:this.wsLinkIndex!=-1?this.targetConfig.link[this.wsLinkIndex]:this.targetConfig.default_link,error_message:""+ error})
                // this.reconnetSocket();
        },
        //数据接收
        msgSocket(event) {
            if(!event.data) return;
          // 如果数据格式为JSON
          if(this.wsType=="json"){
            let type = event.data.split(":::")[0];
            let data = event.data.split(":::")[1]&&JSON.parse(event.data.split(":::")[1]);
            this.operateJsonData(type,data)
          } else{
            let reader = new FileReader()
            reader.readAsArrayBuffer(event.data)
            reader.onload = () => {
                const buf = new Uint8Array(reader.result)
                const response = proto.MyMessage.deserializeBinary(buf)
                console.error('------数据接收 msgSocket 的解析结果------', response)
                if (response) {
                    // 成功回调
                    this.operateData(response) //对数据进行处理
                } //end if
            }
          }
            
        },
        closeSocket(e) {
            //关闭
            this.socket = null;
            console.log('==========webSocket关闭 closeSocket=============')
            this.clearAllTimer() //清除全部定时器
            this.isSocketClose = true;
            !this.isAutoClose && this.reconnetSocket() //不是主动断开时，重新连接socket
        },
        /**
         * 重新连接socket
         * 前端使用wss多路线问题,先优选用link第一个索引的值,超过2次连不上默认的索引值时,出现断网重边连不上,自动到下一个索引的值。
         * 当link列表全部使用完,都无法连网成功时,将直接使用默link.default第一个索引的值,之后也是一直使用默link.default第一个索引的值,
         * 直到页面被刷新
         */
        reconnetSocket() {
            console.error("==============reconnetSocket",this.wsLinkIndex)
            if (this.autoReconnectNum == this.targetConfig.autoReconnectNumMax&&this.wsLinkIndex==-1) return;
            this.autoReconnectNum++;
            setTimeout(() => {
                if (!this.socket || this.socket.readyState == WebSocket.CLOSED) {
                    if(this.autoReconnectNum==2&&this.wsLinkIndex!=-1) {
                        this.autoReconnectNum = 0;
                        this.wsLinkIndex = this.wsLinkIndex==this.targetConfig.link.length-1?-1:this.wsLinkIndex+1;
                    }
                    this.initWebSocket()
                }
            }, this.targetConfig.autoReconnectInterval * 1000)
        },
        /**
         * 发送连接成功消息
         * @params Object {accountName:"",token:"",appkey:""}
         */
        initLogin(accountName=this.configOpt.accid, token=getStore('token'),appkey=this.settingConfig.appKey) {
            if (this.socket && this.socket.readyState == WebSocket.OPEN ) {
              if(this.wsType=="json") {
                var data = {
                  1:"web",
                  2:accountName,
                  3:token,
                  4:"wss",
                  // 5:appkey
                  5:"e10adc3949ba59abbe56e057f20f883e"
                };
                return this.socket.send(`2:::${JSON.stringify(data)}`)
                // return this.socket.send(`2:${JSON.stringify(data)}`)
              }
                var UserLoginWithTokenReqProto = new proto.UserLoginWithTokenReqProto()
                UserLoginWithTokenReqProto.setChannel('wap')
                UserLoginWithTokenReqProto.setAccountName(accountName)
                UserLoginWithTokenReqProto.setToken(token)
                UserLoginWithTokenReqProto.setScheme('ws')
                UserLoginWithTokenReqProto.setAppKey(appkey)
                var messageReq = new proto.MyMessage()
                messageReq.setDataType(
                    proto.MyMessage.DataType.USERLOGINWITHTOKENREQPROTOTYPE
                )
                messageReq.setUserloginwithtokenreqproto(UserLoginWithTokenReqProto)

                this.socket.send(messageReq.serializeBinary());
                // this.checkToken();
                console.log('==========发送连接成功消息===========')
            }
        },
        /**
         *  startHeart     开启心跳
         *  @params accid  访客通讯ID
         *  @params heartBeatWait  心跳包频率
         */
        startHeart(accid, heartBeatWait) {
            this.heartTimerOpt.timer = setInterval(() => {
                // 设置开关，避免js堵塞后造成的频繁请求
                let timestamp = new Date().getTime();
                if(timestamp-this.heartTimerOpt.timestamp < 5 * 1000) return;
                this.heartTimerOpt.timestamp = timestamp;
                if (accid != 'undefined') {
                    if (this.socket && this.socket.readyState == WebSocket.OPEN) {
                      if(this.wsType=='json') return this.socket.send(`5:::${JSON.stringify({1:accid})}`);
                        var heartbeatReq = new proto.HeartbeatReqProto()
                        heartbeatReq.setAccid(accid)
                        var messageReq = new proto.MyMessage()
                        messageReq.setDataType(
                            proto.MyMessage.DataType.HEARTBEATREQPROTOTYPE
                        )
                        messageReq.setHeartbeatreqproto(heartbeatReq)
                        this.socket.send(messageReq.serializeBinary())
                        console.log('==========开启心跳========')
                    } else {
                        clearInterval(this.heartTimerOpt.timer)
                    }
                }
            }, heartBeatWait * 1000)
        },
        //关闭websocket
        closeWebsocket(e){
            if(this.socket){
                this.socket.close();
                this.socket.onclose = function(evt) {
                    console.log("websocket已关闭"); 
                };
            }
        },
        // ------------------------------定时器模块  开始---------------------------------------
        // 超过3秒没有收到ACK回应,将继续重复登录  ---  暂时
        checkToken() {
            reportErrorFunc({action: "WS访客登录超时没有收到ACK回应",request_url:this.wsLinkIndex!=-1?this.targetConfig.link[this.wsLinkIndex]:this.targetConfig.default_link,error_message:"WS访客登录超时没有收到ACK回应"})
            if(this.tokenTimerOpt.timer) return clearInterval(this.tokenTimerOpt.timer);
            this.tokenTimerOpt.timer = setInterval(() => {
                this.getVisitorToken() // 获取访客的token();
                clearInterval(this.tokenTimerOpt.timer);
                this.tokenTimerOpt.timer = null;
                // this.reconnetSocket();
            }, 3000)
        },
        // 轮询判断 超过6秒没有收到ACK回应 则消息状态设为发送失败
        checkMsgStatus() {
            if (this.currentSendList.length == 0) return clearInterval(this.checkMsgTimer);
            let num = 1
            this.checkMsgTimer = setInterval(() => {
                if (num >= 6) {
                    clearInterval(this.checkMsgTimer);
                    this.currentSendList.length != 0 && this.curTalkList.forEach((item) => {
                      if (this.currentSendList.includes(item.msg_id)) {
                        item.status = this.msgAckObj[item.msg_id]||-1;
                        item.status!=-1&&delete(this.msgAckObj,item.msg_id);
                        // this.currentSendList.remove(item.msg_id);
                        // item.status = -1
                      }
                    })
                    this.currentSendList = [];
                } else {
                  num += 1
                }
            }, 1000)
        },
        // 轮询判断 超过10分钟没有对话，则断开
        checkSocket() {
            clearTimeout(this.checkSocketTimer)
            this.checkSocketTimer = setTimeout(() => {
                this.isAutoClose = true
                this.socket.close()
            }, 1000 * 60 * 10)
        },

        // 轮询获取未读消息数
        checkUnRead() {
            if(this.unreadTimerOpt.timer) clearInterval(this.unreadTimerOpt.timer)
            this.unreadTimerOpt.timer = setInterval(() => {
                this.getUnRead()
            }, 20 * 1000)
        },

        // 轮询获取客服在线状态
        checkCustomerStatus() {
            if(this.agentTimerOpt.timer) clearInterval(this.agentTimerOpt.timer);
            this.agentTimerOpt.timer = setInterval(() => {
                // 获取客服状态
                this.getCustomStatus()
            }, 20 * 1000)
        },

        // 设置标题滚动 的定时器
        TitleScrolling(title) {
            if (this.unReadCount == 0) {
                clearInterval(this.checkscrollTimer)
                document.title = this.documentTitle
                return;
            }
            clearInterval(this.checkscrollTimer)
            document.title = this.documentTitle + `- ${title} - `
            this.checkscrollTimer = setInterval(() => {
                // 截取首字符串(第一个)
                var head = document.title.substring(0, 1)
                    // 截取除首字符串外所有字符串(除第一个所有)
                var foot = document.title.substring(1)
                    // 头尾拼接后赋给data => tit属性
                document.title = foot + head + ' '
                if (this.unReadCount == 0) {
                    clearInterval(this.checkscrollTimer)
                    document.title = this.documentTitle
                }
            }, 600)
        },


        // 清除所有定时器
        clearAllTimer() {
            clearInterval(this.heartTimerOpt.timer)
            clearInterval(this.agentTimerOpt.timer)
            clearInterval(this.tokenTimerOpt.timer)
            clearInterval(this.unreadTimerOpt.timer)            
            clearInterval(this.checkMsgTimer)
            clearInterval(this.checkscrollTimer)
            clearTimeout(this.checkSocketTimer)
            
        },

        // ------------------------------定时器模块  结束---------------------------------------

        // -------------------------websocket 连接结束--------------------------------
        // ------------------与外页面取得联系--------------------
        // 接收挂载页面的信息
        getMsgFromParent() {
            let that = this
            window.onmessage = function(event) {
                var data = event.data
                if (data.origin != window.location.origin) return
                console.error('=========getMsgFromParent========',data)
                switch (data.cmd) {
                    case 'sendRoute': //获取路由参数
                        that.getRouteMsg(data.params.url.split('?')[1])
                        that.gwLocation = JSON.parse(data.params.gwLocation);
                        that.isiframe = true;
                        console.error("=====获取路由参数======",this.isiframe)
                        that.$emit("update:isIframe", true)
                        break
                    case 'closeIframe': //主页面关闭了弹窗
                        that.getUnRead()
                        break
                    case 'openIframe': //主页面打开了弹窗
                        that.targetConfig.ws_default_enabled&&that.initWebSocket();
                        that.checkCurListLength(true);
                        that.scrollEvent();
                        that.setTopStyle();
                        break
                    case 'setNotUnRead': //设置已读
                        // that.curTalkList.length != 0 && that.curTalkList.forEach((item) => {
                        //     if (item.status == 1 && item.user_type != 'visitor') {
                        //         that.setMessageReadEvent( item.msg_id)  //告诉消息已读
                        //     }
                        // })

                        break;
                }
            }
        },
        // 向挂载页面传输信息
        sendMsgToParent(cmd, params) {
            window.parent.postMessage({
                    cmd: cmd,
                    params: params,
                    origin: window.location.origin,
                },
                '*'
            )
        },
    },
}