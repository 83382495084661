<template>
  <div class="talk relative" ref='talk' @mouseenter="talkBoxHoverEvent">
    <audio controls="controls" hidden src="../assets/mp3/start.wav" ref="audio"></audio>
      <!-- 聊天记录模块 -->
      <div class="historyWrap relative" :class="isiframe?'historyWrap_iframe':''">
        <div class="parent" id="historyWrap" ref='historyWrap'  v-show="isComplete">
          <div class="historyWrap-fixed-tips flex absolute" ref="historyWrap-fixed-tips"  v-if="showTips && topTips != ''" >
            <span class="historyWrap-fixed-tips-text">{{ topTips }}</span>
            <span class="historyWrap-fixed-tips-img" @click="closeTips"></span>
          </div>

          <div class="historyWrap-fixed-tips2 absolute" @click="scrollToEvent('bottom')" v-if="unReadCount>0&&!isBottom">回到最新位置 </div>
          <div class="historyWrap-bot" ref="historyWrap-bot">
            <p class="historyWrap-bot-more" @click="getMoreHistory">
              <i class="el-icon-loading" v-show="clickHis"></i> <span :class="showMoreBtn?'blueColor':'greyColor'">{{showMoreBtn?"查看更多":"没有更多消息"}}</span>
            </p>
            <!-- 历史消息 -->
            <template v-for="(item, index) in historyList">
              <div :key="index">
                <!-- 左边部分 客服模块 -->
                <leftItem :item="item" :emojiObj="emojiData" :curClickIndex.sync="hcurClickIndex" :curIndex="index" isShowTime v-if="item.user_type !='visitor'" @on-click="leftItemClick" />
                <!-- 右边部分 客户模块 -->
                <rightItem :item="item" :index="index" :emojiObj="emojiData" :curClickIndex.sync="hcurClickIndex" :curIndex="index" isShowTime @on-click="leftItemClick"  v-if="item.user_type == 'visitor'" />
              </div>
            </template>
            <p style="text-align:center;margin-bottom:20px;color:#999;" v-if="historyList.length">--------以上为历史消息---------</p>
            <!-- 当前消息 -->
            <template v-for="(item,index) in curTalkList">
              <div :key="item.msg_id" style="text-align:center">
                <!-- 左边部分 客服模块 -->
                <leftItem :item="item" :emojiObj="emojiData" :curClickIndex.sync="curClickIndex" :curIndex="historyList.length+index" isShowTime v-if="item.user_type !='visitor'&&item.type!=3"  @on-click="leftItemClick" />
                <!-- 右边部分 客户模块 -->
                <rightItem :item="item" :index="index" :emojiObj="emojiData" :curClickIndex.sync="curClickIndex" :curIndex="historyList.length+index" isShowTime @sendAgain="resendMessage" @on-click="leftItemClick" v-if="item.user_type == 'visitor'" :isShowMsgStatus="`true`" :isShowReadStatus="talkViewOpt.isShowReadStatus" />
                <!-- 中间提示语 -->
                <div class="historyWrap-bot-tips" v-if="item.type == 3">
                  <span>{{item.content}}</span>

                </div>
              </div>
            </template>
          </div>
          <div class="historyWrap_line_tips absolute" v-if="lineTips">
            <p>{{lineTips}}</p>
          </div>
        </div>
        
      </div>
      <!-- 输入框模块 -->
      <div class="inputWrap relative" v-show="isComplete">
        <!-- 表情等 -->
        <div class="inputWrap-top flex-csa">
          <div>
            <span class="emoji" @click="showEmojsEvent" ref="emoji"></span>
            <upload  :tenantId="configOpt.tenantId" :accid="configOpt.accid" @on-upload="uploadImg" v-if="talkViewOpt.isShowUpload">
              <span class="imgCut" ></span>
            </upload>
            <span class="leave" @click="leaveWord"></span>
            <span class="rang" :class="{'rang-off':!openAudio}" @click="changeAudio"></span>
          </div>
          <div class="blueColor" @click="sendSelfService">人工服务</div>

        </div>
        <!-- 输入框 -->
        <div class="inputWrap-input">
          <el-input type="textarea" id="talk_input" :rows="5" :placeholder="$t('language.talk.placeholder')" v-model="message" @keydown.enter.native="listen($event)" @input="changeEvent" />
          <div class="inputWrap-input-btn">
            <p>{{ $t("language.talk.tips") }}</p>
            <span @click="sendEvent({type:1,from:'enter'})" :class="['send-btn','absolute',{'pointer':message.trim()!=''}]">{{ $t("language.talk.btn") }}</span>
          </div>
        </div>
        <!-- 表情包 -->
        <div class="inputWrap-emoji absolute" ref="inputWrap-emoji" v-show="showEmojs" >
          <!-- 表情包列表 -->
          <div class="inputWrap-emoji-list absolute">
            <img :src="item"  v-for="(item, key) in emojiObj[curEmoji].obj" :key="key"  @click="chooseEmoji(key)" />
          </div>
          <!-- 表情的tab栏 -->
          <div class="inputWrap-emoji-tab absolute">
            <img :src="item.icon" v-for="(item, key) in emojiObj" :key="key" ref="emoji-tab" @click="changeTab(key)" :class="{ emTabActive: curEmoji == key }" />
          </div>
        </div>
      </div>
    <!-- 留言组件 -->
    <leaveWord :isShow.sync="showLeaveWord" :requestMethods="setTickets" />
    <!-- 邀请评价组件 -->
    <invite :isShow.sync="showInvite" :requestMethods="addEvaluation" />
    <!-- 加载框 -->
    <div class="loading-tip" v-if="!isComplete&&configOpt.configId&&!isAutoClose">
      <i class="el-icon-loading" style="fongt-size:20px;"></i>
      正在连接，请稍等... 
    </div>
  </div>
</template>
<script>
import { getStore,setStore,dateFormat } from "@/utils/utils";
import store from "@/store";
import talk from "../mixins/talk"
import { Base64 } from 'js-base64'
import {
  sendMsg,
  getHistory,
  addEvaluation,
  setTickets,
} from "@/fetch/api";
import url from "@/fetch/url";
import to from "await-to-js";
export default {
  name: "talk",
  mixins:[talk],
  props: {
    width: [Number,String],  // 后端返回的窗口宽度
    theme:String,            // 后端返回的主题色
    allow: Boolean,         //是否允许和父组件通信 iframe
    welcomeTips:String,     //企业欢迎词
    getSessionData:Function,
    configId:String,
    isIframe:Boolean,       //是否外嵌页面
  },
  data(){
    return {
      errItem:null,    //发送失败的数据
      isBottom:false,    //滚动条是否在底部
      timesObj:{          //记录聊天时间显示
        cur:0,
        his:0
      },
      alertStatus:false,   //alert弹窗开关
      curClickIndex:0,    //点击的聊天消息的序号，用于是否显示复制的处理
      hcurClickIndex:0,  //点击的历史聊天消息的序号，用于是否显示复制的处理
    }
  },
  computed: {
    emojiData() {   //获取所有的表情包数据
      let obj = {}
      for (let key in this.emojiObj) {
        obj = {...obj, ...this.emojiObj[key].obj }
      }
      return obj
    },
  },
    // 监听器
  watch: {
    welcomeTips(e){
      this.topTips = e;
    },
    // 监听顶部提示语，设置样式
    topTips(e){
      e&&this.$nextTick(()=>{
        this.setTopStyle();
      })
      !e&&this.$nextTick(()=>{
        this.$refs["historyWrap-bot"].style.paddingTop = "10px";
      })
    },
    isComplete(e){
      e&&this.$emit('update:width', this.dialogWidth.replace('px', '')) // 根据后端返回的尺寸来判断是否要显示全部内容
    }
  },
  created() {
    this.sendMsgToParent("getRoute",{})
    this.allow && this.getMsgFromParent();   //接受消息
    !this.gwLocation&&this.getRouteMsg(window.location.hash.split("?")[1]);
    window.addEventListener('beforeunload', e => this.closeWebsocket(e))
  },
  mounted() {
    // 监听表情模块的点击事件
    document.addEventListener("click", (e) => {
      const emojiBox = this.$refs["inputWrap-emoji"];
      if (e.target != this.$refs["emoji"] && !emojiBox.contains(e.target)) {
        this.showEmojs = false; //这句话的意思是点击其他区域关闭（也可以根据自己需求写触发事件）
      }
    });
    this.documentTitle = document.title;   //保存浏览器标题
    // 监听滚动条事件
    document.getElementById("historyWrap").addEventListener("scroll",this.scrollListenEventevent)
    // 监听网络事件
    window.addEventListener('online', this.updateOnlineStatus);
　　window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy(){
    this.clearAllTimer();   //清除全部定时器
    window.removeEventListener('beforeunload', e => this.closeWebsocket(e)) //离开路由之后断开websocket连接
    window.removeEventListener('online', this.updateOnlineStatus);
　　 window.removeEventListener('offline', this.updateOnlineStatus);
  },

  methods: {
    // 滚动条的监听回调事件
    scrollListenEventevent(event){
      if(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight>100) {
          this.isBottom = false;
        } else {
          this.isBottom = true;
          if(this.unReadCount>0){
            let item = this.curTalkList.find(item=>item.status == 1 && item.user_type != "visitor"&&!!item.msg_id);
            item&&this.setMessageReadEvent(item.msg_id,true) //告诉消息已读
          }
          this.unReadCount = 0;
        }
        // 滚动到顶部加载历史消息
        if(event.target.scrollTop==0){
          this.getMoreHistory();
        }
    },
    // 网络的监听回调函数
    updateOnlineStatus(e){
      console.error("===updateOnlineStatus==",e)
      const { type } = e;
　　　this.onlineStatus = type == 'online';
    },
    // 滚动到顶部或底部事件
    scrollToEvent (type="bottom") {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#historyWrap");
        container.scrollTop = type=="bottom"?container.scrollHeight:0;
        if(type=="bottom"&&this.unReadCount>0){
          let item = this.curTalkList.find(item=>item.status == 1 && item.user_type != "visitor" && item.msg_id);
          this.setMessageReadEvent(item.msg_id,true) //告诉消息已读
        }
        this.unReadCount = 0;
        // 解决初始化时没有滚动到底部的问题
        if(type=="bottom"&&container.scrollTop!=container.scrollHeight){
          setTimeout(()=>{
            container.scrollTop = container.scrollHeight
          },50)
        }
      });
    },
    // hover聊天页面
    talkBoxHoverEvent(){
      if(!this.socket||(this.socket.readyState == this.socket.CLOSED)){   //如果socket关闭了，就重新连接
        this.targetConfig.ws_default_enabled&&this.initWebSocket()
      }
      this.socket&&this.checkSocket();
    },
    // 针对websorket返回的数据进行处理
    operateData(response){
      // console.log("数据处理",response.getDataType(),proto.MyMessage.DataType.MESSAGEREQORRESPROTOTYPE)
      if(!response) return;
      let obj = {};
      //登录成功ACK确认
      if(response.getDataType() == proto.MyMessage.DataType.USERLOGINACKPROTOTYPE){
        console.error("===登录成功ACK确认===",response.getUserloginackproto().getAccountName())
        this.isComplete = true;       //页面数据全部加载完毕
        this.sendParams&&this.sendEvent(this.sendParams);   //重新发送信息
        clearInterval(this.tokenTimerOpt.timer);  //清除获取token的定时器
        // this.lastSessionEvent();
        return;
      }
      // 消息ACK
      if(response.getDataType() == proto.MyMessage.DataType.MESSAGEEVENTACKPROTOTYPE){  //消息ACK确认
        this.checkMsgStatus();   //轮询消息状态
        let data = response.getMessageeventackproto();
        console.error("===消息ACK确认===",data.getMsgId(),data.getType(),this.curTalkList);
        this.curTalkList.forEach(item=>{

          if(item.msg_id==data.getMsgId()){
            item.status = data.getType();   //更改消息状态  1：未读 2：已读
          }
          if(data.getType()==1&&item.msg_id==data.getMsgId()){
            this.unReadSendList.push(item.msg_id);  //添加状态为1的数据
          } else {
            this.unReadSendList = this.unReadSendList.filter(uitem=>uitem!=item.msg_id);//清楚状态为2的数据
          }
        })
        this.currentSendList = this.currentSendList.filter(citem=>citem!=data.getMsgId());//清楚已更改了状态的msg_id
        return;
      }
      //透传消息
      if(response.getDataType() == proto.MyMessage.DataType.CMDMESSAGEPROTOTYPE){
        console.error('============透传消息===================',response.getCmdmessageproto().getContent());
        if(response.getCmdmessageproto().getContent()=="会话创建成功"&&this.curTalkList.findIndex(item=>item.content=="会话创建成功")!=-1) return;
        obj = { type:3, content:response.getCmdmessageproto().getContent() };
        this.curTalkList.push(obj);   //将数据添加到当前会话列表中
        this.scrollToEvent("bottom","newMsg");   //滚动到底部
        return;
      }
      if(response.getDataType() == proto.MyMessage.DataType.MESSAGEREQORRESPROTOTYPE){
        var responseData = response.getMessagereqorresproto();
        var conversation_type = responseData.getConversationType();  //会话类型 1代表单聊,2代表群聊,3代表客服
        var session_id = responseData.getSessionId();
        var from_accid = responseData.getFrom();

        if(this.curTalkList.some(item=>item.msg_id==responseData.getMsgId())) return;  //判断是否已存在该数据
        obj = {
          msg_id: responseData.getMsgId(),
          msg_type:responseData.getMsgType(),  //消息类型  0：纯文本 1：纯文本 2:图片 3：音频 4：视频 5：地理位置 6:转发纯文本 80:撤回消息内容 90:正在输入提醒消息内容 66:命令(信令)消息 77:扩展消息
          seq_id:responseData.getSequenceId(),
          user_type: responseData.getFromRole(),     //发送者角色,robot;agent;visitor;dispatcher
          avatar: responseData.getAvatar()||"",
          nickname: responseData.getFromNick(),
          time: dateFormat("YYYY-mm-dd HH:MM:SS", new Date(responseData.getTime()*1000)),
          content: responseData.getContent(),  //消息内容
          status:1,   //未读
          json:responseData.getMsgType()==77||responseData.getMsgType()==66||responseData.getMsgType()==2?JSON.parse(responseData.getJson()):""
        }
        // console.log("=====obj=====",obj,responseData)
        this.curTalkList.push(obj);   //将数据添加到当前会话列表中

        this.isBottom&&this.scrollToEvent("bottom");   //滚动到底部
      }

    },
    // 处理json数据
    operateJsonData(type,data){
      // console.log("数据处理",response.getDataType(),proto.MyMessage.DataType.MESSAGEREQORRESPROTOTYPE)
      // if(!response) return;
      let obj = {};
      if(type == 17) {
          data[4]!="E1"&&this.$message.error(data[6]);
					// 为E1级代表严重错误,客户端将马上停止自动断网连接功能
					if(data[4]=="E1") {
            if(this.alertStatus) return;
            this.isComplete = true;
            this.isAutoClose = true;
            this.socket.close();
            this.canGetSession = 1;
            this.alertStatus = true;
            this.$alert('当前账号从其他平台登录', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.alertStatus = false;
                this.init(this.configOpt.configId);
              }
            });
          } else {
            this.getVisitorToken().then(()=>{this.initLogin()})
          }
          return;
			};
      //登录成功ACK确认
      if(type==16){
        console.error("===登录成功ACK确认===")
        this.isComplete = true;       //页面数据全部加载完毕
        this.sendParams&&this.sendEvent(this.sendParams);   //重新发送信息
        clearInterval(this.tokenTimerOpt.timer);  //清除获取token的定时器
        this.$store.state.errorOpt.action=="WS访客登录超时没有收到ACK回应"&&clearTimeout(this.$store.state.errorTimer);  //清除异常的定时器
        // this.lastSessionEvent();
        return;
      }
      // 消息ACK
      if(type==14){  //消息ACK确认
        // this.checkMsgStatus();   //轮询消息状态
        setTimeout(()=>{
            let itemIndex = this.findLastIndex(this.curTalkList,"msg_id",data[2]);
						if(itemIndex==-1){
							this.msgAckObj[data[2]] = data[3];
						} else {
							this.curTalkList[itemIndex].status = data[3]; //更改消息状态  1：未读 2：已读
							data[3] == 1&&this.unReadSendList.push(this.curTalkList[itemIndex].msg_id); //添加状态为1的数据
							data[3] != 1&&(this.unReadSendList = this.unReadSendList.filter(uitem => uitem != this.curTalkList[itemIndex].msg_id)); //清楚状态为2的数据
						}
						this.currentSendList = this.currentSendList.filter(citem => citem != data[2]);
						this.checkMsgStatus(); //轮询消息状态
				},0)
        // this.curTalkList.forEach(item=>{

        //   if(item.msg_id==data[2]){
        //     item.status = data[3];   //更改消息状态  1：未读 2：已读
        //   }
        //   if(data[3]==1&&item.msg_id==data[2]){
        //     this.unReadSendList.push(item.msg_id);  //添加状态为1的数据
        //   } else {
        //     this.unReadSendList = this.unReadSendList.filter(uitem=>uitem!=item.msg_id);
        //   }
        // })
        // this.currentSendList = this.currentSendList.filter(citem=>citem!=data[2]);
        return;
      }
      //透传消息
      if(type==13){
        console.error('============透传消息===================');
        if(data[4]=="会话创建成功"&&this.curTalkList.findIndex(item=>item.content=="会话创建成功")!=-1) return;
        // 特殊透传消息  排队人数/对方正在输入
        if(data[5]){  
          let obj = Base64.decode(data[5]);
          this.lineTips = data[4];
          setTimeout(()=>{
            this.lineTips = "";
          },obj.params?obj.params.duration*1000:4000)
        } else {
          obj = { type:3, content:data[4] };
          this.curTalkList.push(obj);   //将数据添加到当前会话列表中
        }
        return;
      }
      if(type==4){
        if(this.curTalkList.some(item=>item.msg_id==data[1])) return;  //判断是否已存在该数据
        // 判断当前消息是客服发送的 但是不是当前客服，则切换客服
        if(data[19]=="agent"&&this.lastSessionMsg.last_agent_accid!=data[9]){
          console.log("===切换客服后 客服的accid====",data[9])
          this.agentMsg.avatar = data[17]?data[17].indexOf('http')>-1?data[17]:this.targetConfig.img_domain_url+"/"+data[17]:"";
          this.agentMsg.nickname = data[18];
          this.lastSessionMsg.last_agent_accid = data[9];
          this.checkCustomerStatus();
          this.checkUnRead();
          this.$emit("getAgent",this.agentMsg);
        }
        obj = {
          msg_id: data[1],
          msg_type:data[14],  //消息类型  0：纯文本 1：纯文本 2:图片 3：音频 4：视频 5：地理位置 6:转发纯文本 80:撤回消息内容 90:正在输入提醒消息内容 66:命令(信令)消息 77:扩展消息
          seq_id:data[2],
          user_type: data[19],     //发送者角色,robot;agent;visitor;dispatcher
          avatar: data[17]?data[17].indexOf('http')>-1?data[17]:this.targetConfig.img_domain_url+"/"+data[17]:"",
          nickname: data[18],
          time: this.judgeTime(new Date(),"curTalk"),
          content: data[15],  //消息内容s
          status:1,   //未读
          json:data[14]==77||data[14]==66||data[14]==2?JSON.parse(Base64.decode(data[21])):""
        }
        // console.error(obj)
        this.curTalkList.push(obj);   //将数据添加到当前会话列表中      
        this.isBottom&&this.scrollToEvent("bottom");   //滚动到底部
        this.checkCurListLength();
      }
    },
    /**
     * 发送消息
     * @params type 消息类型 1：发送纯文本 2：发送图片消息  55：重发消息
     * @params msgIndex 消息序号 用于定位重发消息
     * @params ext_rule 类型   发送图片消息特有
     * @params url      链接   发送图片消息特有
     */
    sendEvent({...params}) {
      if(!this.onlineStatus) return this.$alert('消息发送失败，请检查网络设置', '提示', {
          confirmButtonText: '确定',
          callback: action => {}
      });
      this.sendParams = params;   //保存参数，用于断网重连后的重新发送
      if(this.isAutoClose) return false;
      if(!this.isComplete) return this.$message.warning("连接中，请勿操作");
      // if(this.targetConfig.ws_default_enabled&&(!this.socket||this.socket.readyState == WebSocket.CLOSED)) return this.$message.warning("连接已断开，请刷新页面");

      if (params.from=='enter'&&this.message.trim() == "") return;
      let timestamp = Date.parse(new Date())/1000;
      let msgItem = {
        user_type: "visitor",
        avatar: this.targetConfig.tenant_avatar?this.targetConfig.tenant_avatar:"https://livechat.kr65.com/resources/images/operator.png",
        nickname: this.visitorMsg.nickname,
        time:this.judgeTime(new Date(),"curTalk",this.curTalkList.length==0?1:0),
        msg_type:params.type,
        status:0,
        timestamp:timestamp,
        nonce:this.getRandomNum()+timestamp
      }
      switch(params.type){
        case 1:   //纯文本
          msgItem.content =params.message||this.message;
          this.curTalkList.push(msgItem);
          break;
        case 2:  //图片类型
          msgItem.content = "发送图片";
          msgItem.ext_rule = params.ext_rule;
          msgItem.url = params.url;
          msgItem.json = {entity:[{url:params.url}]};
          this.curTalkList.push(msgItem);
          break;
        case 55:  //重发消息
          msgItem = {...msgItem,...this.curTalkList[params.msgIndex],nonce:this.getRandomNum()+this.curTalkList[params.msgIndex].timestamp,status:0};
          this.curTalkList.splice(params.msgIndex,1,msgItem)
          break;
      }
      this.playMusic();
      this.sendMsgApi({sessionId:this.lastSessionMsg.session_service_id,toAccid:this.lastSessionMsg.last_agent_accid,data:{"msg_type":msgItem.msg_type,"content":msgItem.content||"",ext_rule:msgItem.ext_rule||"",url:msgItem.url||"",avatar:"",timestamp:msgItem.timestamp,nonce:msgItem.nonce}}).then(res=>{
        console.log('======发送消息返回的结果======', res);
        msgItem.msg_id = res.msg_id; //记录数据的id
        //判断是否连接了websocket
        if(!this.socket){        
          msgItem.status = 2;
          this.curTalkList.splice(this.curTalkList.length-1,1,msgItem);    //处理响应式的问题
          this.curTalkList.push({
            msg_type:res.ask.msg_type,          
            msg_id:res.ask.msg_id,    
            content:res.ask.body,
            json:res.ask.from_user.ext,
            user_type:res.ask.from_user.user_type,          
            avatar:res.ask.from_user.avatar.indexOf('http')>-1?res.ask.from_user.avatar:this.targetConfig.img_domain_url+"/"+res.ask.from_user.avatar
          })
          this.scrollToEvent("bottom")
        } else {
          // 判断是否存在ack已响应的数据
          if(JSON.stringify(this.msgAckObj)!='{}'){
						msgItem.status = this.msgAckObj[msgItem.msg_id]||0;
						msgItem.status!=0&&delete(this.msgAckObj,msgItem.msg_id);
          }
          //保存正在发送的数据，等待ack响应后再更改列表的状态
          msgItem.status==0&&this.currentSendList.push(msgItem.msg_id);
          //轮询消息状态    
          this.checkMsgStatus();       
        }
        //解决数组响应式的问题
        params.type==55&&this.curTalkList.splice(params.msgIndex,1,msgItem);  
        this.sendParams = null;
        this.targetConfig.ws_default_enabled = res.auto_connect_ws;
      }).catch(err=>{
        msgItem.status = -1;//失败
        //解决数组响应式的问题
        params.type==55&&this.curTalkList.splice(params.msgIndex,1,msgItem);  
      })
      this.message = "";
      params.type!=55&&this.scrollToEvent("bottom");
      this.checkCurListLength();   
    },

    /**
     * 查看历史消息
     */
    async getMoreHistory() {
      console.error("========获取历史消息=======")
      if(!this.showMoreBtn||!this.configOpt.tenantId||!this.configOpt.tenantId) return;
      let status = this.historyList.length>0?"top":"bottom";   //判断历史数据的长度，如果有数据，则滚动到顶部，没有则滚动到底部
      if (this.clickHis) return this.$message.warning("请求中，请勿频繁操作");
      this.clickHis = true;   //点击了查看更多
      let seq_id = this.historyList.length > 0 ? this.historyList[0].seq_id : this.curTalkList.length>0?this.curTalkList[0].seq_id:0;
      const [err, res] = await to(getHistory({ tenantId: this.configOpt.tenantId, agentAccid: this.lastSessionMsg.last_agent_accid, sessionId: this.lastSessionMsg.session_service_id, data: { from_seqid: seq_id, size: "20" }, }));
      this.clickHis = false;
      if(err||res.code!=200) return;
      if(res.entity.length<20) this.showMoreBtn = false;      
      if(res.entity.length==0) return;
      //保存比较的时间
      this.timesObj.his = res.entity[res.entity.length-1].body.create_date_time * 1000;   
			let arr = [];
      res.entity.forEach((item,index)=>{
        if(!this.historyList.some(hitem=>hitem.seq_id==item.seq_id)) {  //过滤相同的消息
          let obj = {
            msg_id:item.msg_id,
            msg_type:item.msg_type,
            seq_id:item.seq_id.toString(),
            user_type:item.from_user.user_type,
            nickname:item.from_user.nickname,
            avatar:item.from_user.avatar?item.from_user.avatar.indexOf('http')>-1?item.from_user.avatar:this.targetConfig.img_domain_url+"/"+item.from_user.avatar:"",
            content:item.body.bodies.msg,
            time: item.body.create_date_time*1000,
            json:item.msg_type==77||item.msg_type==66||item.msg_type==2?JSON.parse(Base64.decode(item.body.ext)):""
          };
          arr.unshift(obj);
        }
      })
      arr.forEach((item,index)=>{
				index==0&&(item.time = dateFormat(new Date(item.time).toDateString()!=new Date().toDateString()?"YYYY-mm-dd HH:MM":"HH:MM", new Date(item.time)))
				index!=0&&(item.time = this.judgeTime(item.time,"history"))
      })
      this.historyList = [...arr,...this.historyList];
      //聊天页面滚动到顶部或底部
      this.scrollToEvent(status);   
    },
    // ------------------------------相关操作  开始---------------------------------------
    // -------------------------输入框 开始--------------------------------
    // 输入框内容发生变化
    async changeEvent(){
      if(this.isComplete&&(!this.targetConfig.ws_default_enabled||(this.socket&&this.socket.readyState == this.socket.OPEN))&&this.message.trim()!=""){
        if(this.lastDateTime=="" || new Date().getTime()-this.lastDateTime>=3000){
          let res = await this.visitorInput(this.message);
          this.lastDateTime = new Date().getTime()
        }
      }
    },
    // 点击enter按钮
    listen(event) {
      if (event.ctrlKey && event.keyCode == 13) {     //用户点击了ctrl+enter触发
        this.message += "\n";
      } else {               //用户点击了enter触发
        this.sendEvent({type:1,from:"enter"});
        event.preventDefault(); // 阻止浏览器默认换行操作
        return false;
      }
    },
    // 重发消息
    resendMessage(index,e){
      this.sendEvent({type:55,msgIndex:index});
    },

    // -------------------------输入框 结束--------------------------------

    // -----------------------表情包模块 开始-------------------------

    // 是否显示表情包弹窗
    showEmojsEvent() {
      this.showEmojs = !this.showEmojs;
    },
    // 切换表情包数据集
    changeTab(key) {
      this.curEmoji = key;    //选中的表情包集的key
    },
    // 选择表情包
    chooseEmoji(e) {
      this.insertInputTxt("talk_input", e);  //将表情包插入到文本中
      this.showEmojs = false;                //隐藏表情包弹窗
    },
    // 获取input框中光标的位置，插入内容
    insertInputTxt(id, insertTxt = "") {
      var elInput = document.getElementById(id);
      var startPos = elInput.selectionStart;
      var endPos = elInput.selectionEnd;
      if (startPos === undefined || endPos === undefined) return;
      var txt = elInput.value;
      var result = txt.substring(0, startPos) + insertTxt + txt.substring(endPos);
      elInput.value = result;
      this.message = result;
      elInput.focus();
      this.$nextTick(() => {
        elInput.selectionStart = startPos + insertTxt.length;
        elInput.selectionEnd = startPos + insertTxt.length;
      });
    },
    // -----------------------表情包模块 结束-------------------------
    // -----------------------提示音 开始--------------------------------
    // 是否开启提示音
    changeAudio(){
      this.openAudio = !this.openAudio;
    },
    // 播放发送、接受消息的声音
    playMusic() {
      if(!this.openAudio) return;
      this.$refs.audio.currentTime = 0; //从头开始播放提示音
      this.$refs.audio.play(); //播放
    },
    // ------------------------提示音 结束--------------------------------
    // 关闭提示
    closeTips() {
      this.showTips = false;
      this.topTips = "";   //清空顶部提示
    },
    // 根据提示语来设置顶部样式
    setTopStyle(){
      let height= this.$refs["historyWrap-fixed-tips"].offsetHeight;
      this.$refs["historyWrap-bot"].style.paddingTop = (this.$refs["historyWrap-fixed-tips"].offsetHeight+10)+"px"
    },
    // ------------------------留言 开始--------------------------------
    // 打开留言
    leaveWord(){
      this.showLeaveWord = true;
    },
    // 留言的请求
    setTickets(formData){
      setTickets({tenantId:this.configOpt.tenantId,data:{"visitor_accid":this.configOpt.accid,config_id:this.configOpt.configId,session_id:this.lastSessionMsg.session_service_id, ...formData}}).then(res=>{
        if(res.code!=200) return;
        this.$message.success("留言成功")
        this.showLeaveWord = false;
      }).catch(err=>{})
    },
    // -------------------------留言 结束--------------------------------

    // 点击 截图
    uploadImg(url){
      console.error("========截图========",url)
      this.sendEvent({type:2,ext_rule:"img",url:url})
    },

    // 点击 人工服务
    sendSelfService(){
      this.message = "转人工";
      this.sendEvent({type:1});
    },


    // -------------------------点击扩展消息等 开始--------------------------------

    // 点击扩展消息和信令消息
    leftItemClick(e,type){
      console.log(e, type)
      switch(type){
        case "product":   //产品
        case "order":     //订单
          window.open(e);
          break;
        case "self":     //点击人工服务的关键词
          this.message = e;
          this.sendEvent({type:1});
          break;
        case "invite":     //邀请评价
          this.showInvite = true;
          break;
      }
    },

    // 评价的请求
    addEvaluation(formData){
      addEvaluation({tenantId:this.configOpt.tenantId,data:{"visitor_accid":this.configOpt.accid, ...formData}}).then(res=>{
        console.log(res, '');
        if(res.code!=200) return;
        this.$message.success("评价成功")
        this.showInvite = false;
      }).catch(err=>{

      })
    },

    // -------------------------点击扩展消息等 结束--------------------------------

    /**
     * 当前消息的时间(格式为:年月日时分)!=当前时间(格式为:年月日时分)时,那么显示时间,并且将当前消息的时间赋给于当前时间,进行判断下一条读取的消息前否要加时间显示。
     * @params String msgDate 当前消息的时间撮
     * @params String origin  操作的数据
     * @params String type    1:不需要比较时间 直接加时间
     */
    judgeTime(msgDate,origin,type){
      if(origin=="curTalk"){
        type==1&&(this.timesObj.cur = this.timesObj.his)
        if((type==1&&!this.timesObj.cur)||new Date(msgDate).getTime() - new Date(this.timesObj.cur).getTime()>5*60*1000){
						this.timesObj.cur = msgDate;
						return dateFormat(new Date(msgDate).toDateString()!==new Date().toDateString()?"YYYY-mm-dd HH:MM":"HH:MM", new Date(msgDate))
				}
				return ""
      }
      if(origin=="history"){
					// 1、判断与上一个时间记录是否相差5分钟
					// 2、判断跟当前日期的关系
					if(new Date(msgDate).getTime() - new Date(this.timesObj.his).getTime()>5*60*1000){
						this.timesObj.his = msgDate;
						return dateFormat(new Date(msgDate).toDateString()!==new Date().toDateString()?"YYYY-mm-dd HH:MM":"HH:MM", new Date(msgDate))
					}
					return ""
				}
    },

    // 判断当前聊天数据是否已到达极限值；如果当前聊天数达到最大值，则滚动到底部并保留规定数量的数据
			checkCurListLength(isDel){
				if((!!isDel&&this.curTalkList.length>this.delCurData)||this.maxCurData<=this.curTalkList.length){
					this.historyList = [];
					this.isLast = false;
					this.scrollToEvent();
					this.curTalkList.splice(0,this.delCurData);
				}
			},

    // 实现findLastIndex  
		findLastIndex(array,key,value) {
			for( let i = array.length-1; i >=0; i-- ) {
					if(array[i][key]==value) return i;
			      // if( cb.call(context, array[i], i) ) return index
			    }
			    return -1
    },
    // 获取随机数
    getRandomNum(Min=100000,Max=999999){
      var Range = Max - Min;
      var Rand = Math.random();
      return (Min + Math.round(Rand * Range));
    },
    

  },



};
</script>
<style lang="scss" scoped>
@import "@/assets/style/basic.scss";
.talk {
  width: 100%;
  // z-index: -1;
  .loading-tip{
    position: fixed;
    padding: 8px 15px;
    box-sizing: border-box;
    top:75px;
    left: 50%;
    z-index:9999;
    transform: translateX(-50%);
    background-color: #f3eba1;
    color: #999;
    border-radius: 4px;
    font-size: 16px;
  }
  .historyWrap {
    min-height: 200px;
    height: calc(100vh - 270px);
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;
    &.historyWrap_iframe{
      height: calc(100vh - 205px);
    }
    .parent{
      min-height: 200px;
      height: 100%;
      width: 100%;
      background-color: #f5f5f5;
			overflow: auto; /*关键点*/
    }
    &-fixed-tips {
      background-color:#ffecd5;
      line-height: 20px;
      padding: 5px 10px;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: space-between;
      color: #000;
      left: 0;
      z-index: 1;
      width: 100%;
      // min-width: 400px;
      &-text {
        width: 95%;
        // min-width: 400px;
        word-break: break-all;
      }
      &-img {
        display: inline-block;
        @include w_h(26, 18);
        background: url("../assets/images/6_icon_common@2x-675956b127.png")
          no-repeat;
        background-position: 0 -5px;
        background-size: 34px auto;
        cursor: pointer;
      }
    }
    &-fixed-tips2{
      z-index: 2;
      top: 100px;
      right: 0;
      background-color: #f8f3f2;
      border: 1px solid rgb(223, 222, 222);
      box-shadow: 0px 0px 5px rgba(0,0,0,.1);
      border-right: none;
      font-size: 12px;
      padding: 7px 10px;
      border-radius: 50px 0 0 50px;
      @include font-main-color($theme_sky);
      cursor: pointer;
    }
    &-bot {
      width: 100%;
      // height: 300px;
      text-align: left;
      padding: 10px 15px 15px;
      box-sizing: border-box;
      &-more {
        width: 120px;
        // height: 20px;
        margin: 0 auto 10px;
        text-align: center;

        span.blueColor{
          cursor: pointer;
          @include font-main-color($theme_sky);
        }
        span.greyColor{
          display: inline-block;
          padding: 5px 10px;
          background-color: rgb(192, 189, 189);
          color: #fff;
          border-radius: 4px;
          margin-bottom: 5px;
        }
      }

      &-tips {
        box-sizing: border-box;
        padding-bottom: 15px;
        span{
          display: inline-block;
          padding: 5px;
          margin: 0 auto;
          box-sizing: border-box;
          background-color: #c9ced3;
          color:#fff;
          border-radius: 5px;
          text-align: center;
        }
      }
    }

    &_line_tips{
      width: 100%;
      bottom: 0;
      height: 30px;
      line-height: 30px;
      background-color:#ffecd5;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }

  .inputWrap {
    background-color: #fff;
    padding-bottom: 35px;
    box-sizing: border-box;
    min-height: 200px;

    &-top {
      text-align: left;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .blueColor{
        cursor: pointer;
        @include font-main-color($theme_sky);
      }
      span {
        display: inline-block;
        overflow: hidden;
        margin: 6px 0 0 6px;
        cursor: pointer;
        font-size: 12px;
        width: 28px;
        height: 28px;
        background-image: url(../assets/images/6_toolbaricon@2x-aec37678b3.png);
        background-size: 86px auto;
        &:hover {
          background-color: #ebe7e7;
        }
      }
      span.emoji {
        background-position: -2px -35px;
      }
      span.rang {
        background-position: -2px -227px;
        &.rang-off{
          background-position: -2px -387px;
        }
      }
      span.leave{
        background-position: -2px -290px;
      }
      span.imgCut{
        background-position: -2px -418px;
      }
    }
    &-input {
      position: relative;
      ::v-deep .el-textarea__inner {
        border: none !important;
      }
      &-btn {
        background-color: #fff;
        position: absolute;
        bottom: -28px;
        width: 100%;
        padding: 10px;
        height: 40px;
        box-sizing: border-box;
        p {
          text-align: center;
          margin-right: 40px;
          line-height: 20px;
        }
        .send-btn {
          bottom: 0;
          right: 10px;
          @include w_h_lh(90, 32, 32);
          display: inline-block;
          text-align: center;
          border-radius: 4px;
          color: #fff;
          background-color: #ccc;;
          font-size: 14px;
          &.pointer{
            @include background-main-color($theme_sky);
            cursor: pointer;
          }
        }
      }
    }
    &-emoji {
      top: -215px;
      left: 5px;
      height: 210px;
      width: 480px;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: left;
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 5px 10px rgba(51, 50, 50, 0.1);
      img {
        margin: 5px;
        width: 30px;
        cursor: pointer;
        &:nth-of-type(2){
          padding: 3px;
          box-sizing: border-box;
        }
        &:hover {
          background-color: #ccc;
          border-radius: 4px;
        }
      }
      .emTabActive {
        background-color: #ccc;
        border-radius: 4px;
      }
      &-list {
        height: 195px;
        overflow-y: auto;
      }
      &-tab {
        width: 100%;
        bottom: 0;
        background-color: #fff;
      }
    }
  }
}
</style>